<h1 mat-dialog-title>
  <span>Upload report rows</span>
</h1>
<div mat-dialog-content>
  <form [formGroup]="uploadForm" (ngSubmit)="submit()">
    <input type="file" class="full-width" formControlName="file" required (change)="fileProgress($event)">
  </form>
  <mat-progress-bar *ngIf="!!loading" mode="indeterminate" color="primary"></mat-progress-bar>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="cancel()" [disabled]="!!loading">Cancel</button>
  <button mat-raised-button color="primary" (click)="submit()" [disabled]="!!loading">Upload</button>
</div>
