import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseHttpService } from './base-http.service';
import { AuthService } from './auth.service';
import { UserModel } from '../models/user.model';
import { ApiResponseModel } from '../models/api-response.model';
import { CreateUserModel } from '../models/create-user.model';
import { UpdateUserModel } from '../models/update-user.model';
import { UpdateUserClientsModel } from '../models/update-user-clients.model';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseHttpService {

  constructor(private authService: AuthService, private httpClient: HttpClient) {
    super();
  }


  create(data: CreateUserModel): Observable<ApiResponseModel<UserModel>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.post<ApiResponseModel<UserModel>>(`/api/users/create`, data, this.httpOptions);
  }

  get(all: boolean, clientId: number = 0, page: number = 0, size: number = 10, sortField: string = 'name', sortDescending: boolean = false, searchTerm: string = ''): Observable<ApiResponseModel<Array<UserModel>>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.get<ApiResponseModel<Array<UserModel>>>(`/api/users?cid=${clientId}&all=${(!!all ? 1 : 0)}&p=${page}&s=${size}&sf=${sortField}&sd=${(!!sortDescending ? 1 : 0)}&t=${searchTerm}`, this.httpOptions);
  }

  getUser(id: number): Observable<ApiResponseModel<UserModel>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.get<ApiResponseModel<UserModel>>(`/api/users/${id}`, this.httpOptions);
  }

  update(data: UpdateUserModel): Observable<ApiResponseModel<string>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.post<ApiResponseModel<string>>(`/api/users/update`, data, this.httpOptions);
  }

  updateClients(data: UpdateUserClientsModel): Observable<ApiResponseModel<string>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.post<ApiResponseModel<string>>(`/api/users/update-clients`, data, this.httpOptions);
  }

  delete(id: number): Observable<ApiResponseModel<boolean>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.delete<ApiResponseModel<boolean>>(`/api/users/${id}`, this.httpOptions);
  }
}
