import { Injectable, Output, EventEmitter } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  @Output() onChange: EventEmitter<boolean> = new EventEmitter<boolean>();


  constructor() {
  }


  show(): void {
    this.onChange.emit(true);
  }

  hide(): void {
    this.onChange.emit(false);
  }
}
