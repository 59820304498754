import { Component, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

import { ClientService } from '../core/services/client.service';
import { ReportService } from '../core/services/report.service';
import { UserService } from '../core/services/user.service';
import { AuthService } from '../core/services/auth.service';
import { ApiResponseStatus } from '../core/models/api-response.model';
import { ToasterService } from '../core/services/toaster.service';
import { LoaderService } from '../core/services/loader.service';
import { ClientModel } from '../core/models/client.model';
import { ReportModel } from '../core/models/report.model';
import { UserModel } from '../core/models/user.model';
import { SelectableModel } from '../core/models/selectable.model';
import { Helper } from '../core/helper';
import { AccessLevel } from '../core/models/access-level.model';
import { ConfirmDialogComponent } from '../core/components/confirm-dialog/confirm-dialog.component';
import { DialogSize, DialogService } from '../core/services/dialog.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements AfterViewInit {

  public clients: Array<SelectableModel<ClientModel>> = [];
  public reports: Array<SelectableModel<ReportModel>> = [];
  public consultants: Array<SelectableModel<UserModel>> = [];
  public selectedClient: ClientModel;
  public totalClients: number;
  public totalReports: number;
  public totalConsultants: number;

  private clientPermissions: Array<UserModel> = [];


  constructor(
    private authService: AuthService,
    private clientService: ClientService,
    private reportService: ReportService,
    private userService: UserService,
    private dialogService: DialogService,
    private toasterService: ToasterService,
    private loaderService: LoaderService,
    private router: Router
  ) { }


  ngAfterViewInit(): void {
    this.loaderService.show();
    this.getClients(true);
    this.getReports(true);
    this.getConsultants(true);
  }

  selectClient(id): void {
    this.clients.forEach(c => c.selected = false);

    let client = this.clients.find(c => c.data.id === id);

    client.selected = true;

    this.selectedClient = Helper.clone(client.data);

    this.getReports(false);

    this.getPermissions();
  }

  createClient(): void {
    this.router.navigate([`/clients/add`]);
  }

  editClient(id: number): void {
    this.router.navigate([`/clients/${id}`]);
  }

  deleteClient(id: number): void {
    this.dialogService.open(ConfirmDialogComponent, DialogSize.Small, {
      title: 'Delete consultant?',
      message: `Are you sure that you want to delete this client?`
    }).subscribe(result => {
      if (!!result) {
        this.clientService.delete(id).subscribe(response => {
          if (!!response.data) {
            this.toasterService.success('Client deleted');
            this.getClients(true);
          }
          else {
            this.toasterService.danger('Error deleting client');
          }
        });
      }
    });
  }

  createReport(): void {
    this.router.navigate([`/reports/add`]);
  }

  editReport(id: number): void {
    this.router.navigate([`/reports/${id}`]);
  }

  deleteReport(id: number): void {
    this.dialogService.open(ConfirmDialogComponent, DialogSize.Small, {
      title: 'Delete report?',
      message: `Are you sure that you want to delete this report?`
    }).subscribe(result => {
      if (!!result) {
        this.reportService.delete(id).subscribe(response => {
          if (!!response.data) {
            this.toasterService.success('Report deleted');
            this.getReports(true);
          }
          else {
            this.toasterService.danger('Error deleting report');
          }
        });
      }
    });
  }

  createConsultant(): void {
    this.router.navigate([`/consultants/add`]);
  }

  addRemovePermission(add: boolean, consultantId: number): void {
    if (!this.selectedClient) {
      return;
    }

    const consultant = this.consultants.find(c => c.data.id === consultantId);

    if (!!add) {
      this.clientService.addPermission({
        clientId: this.selectedClient.id,
        userId: consultant.data.id
      })
        .subscribe(response => {
          if (response.status === ApiResponseStatus.OK) {
            this.toasterService.success('Permission added');
          }
          else {
            this.toasterService.danger('Failed to add permission');
          }
        });
    }
    else {
      this.clientService.removePermission({
        clientId: this.selectedClient.id,
        userId: consultant.data.id
      })
        .subscribe(response => {
          if (response.status === ApiResponseStatus.OK) {
            this.toasterService.success('Permission removed');
          }
          else {
            this.toasterService.danger('Failed to remove permission');
          }
        });
    }
  }

  searchClients(event: any): void {
    if (event.target.value !== '' && event.target.value.length < 3) {
      return;
    }

    this.getClients(false, event.target.value);
  }

  searchReports(event: any): void {
    if (event.target.value !== '' && event.target.value.length < 3) {
      return;
    }

    this.getReports(false, event.target.value);
  }

  searchConsultants(event: any): void {
    if (event.target.value !== '' && event.target.value.length < 3) {
      return;
    }

    this.getConsultants(false, event.target.value);
  }

  hasAccess(level: number): boolean {
    return this.authService.hasAccess(level);
  }


  private getClients(init: boolean, term: string = ''): void {
    if (!init) {
      this.loaderService.show();
    }

    this.clientService.get(this.authService.getLoggedUser().id, false, 0, 20, 'name', false, term)
      .subscribe(response => {
        if (response.status === ApiResponseStatus.OK) {
          this.totalClients = response.totalItems;
          this.clients = response.data.map(c => {
            return {
              selected: false,
              data: c
            };
          });
        }
        else {
          this.toasterService.danger('Failed to retrieve clients list - ' + response.message);
        }

        this.loaderService.hide();
      });
  }

  private getReports(init: boolean, term: string = ''): void {
    if (!init) {
      this.loaderService.show();
    }

    const loggedUser = this.authService.getLoggedUser();

    this.reportService.get(((loggedUser.accessLevel === AccessLevel.ADMIN || loggedUser.accessLevel === AccessLevel.SYSTEM_ADMIN)
      ? -1
      : loggedUser.id), false, (!!this.selectedClient ? this.selectedClient.id : 0), 0, 20, 'name', false, term)
      .subscribe(response => {
        if (response.status === ApiResponseStatus.OK) {
          this.totalReports = response.totalItems;
          this.reports = response.data.map(r => {
            return {
              selected: false,
              data: r
            };
          });
        }
        else {
          this.toasterService.danger('Failed to retrieve reports list - ' + response.message);
        }

        this.loaderService.hide();
      });
  }

  private getConsultants(init: boolean, term: string = ''): void {
    if (!this.hasAccess(2) && !this.hasAccess(1)) {
      return;
    }

    if (!init) {
      this.loaderService.show();
    }

    this.userService.get(true, 0, 0, 0, 'name', false, term)
      .subscribe(response => {
        if (response.status === ApiResponseStatus.OK) {
          this.totalConsultants = response.totalItems;
          this.consultants = response.data.map(c => {
            return {
              selected: !this.selectedClient ? false : !this.clientPermissions.find(p => p.id === c.id),
              data: c
            }
          });
        }
        else {
          this.toasterService.danger('Failed to retrieve consultants list - ' + response.message);
        }

        this.loaderService.hide();
      });
  }

  private getPermissions(): void {
    if (!this.hasAccess(2) && !this.hasAccess(1)) {
      return;
    }

    this.userService.get(true, this.selectedClient.id)
      .subscribe(response => {
        if (response.status === ApiResponseStatus.OK) {
          this.clientPermissions = response.data;

          this.consultants.forEach(c => {
            c.selected = !!this.clientPermissions.find(r => r.id === c.data.id);
          });
        }
        else {
          this.toasterService.danger('Failed to retrieve consultants list - ' + response.message);
        }

        this.loaderService.hide();
      });
  }
}
