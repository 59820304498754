<div class="header">
  <mat-icon (click)="back()">arrow_back_ios</mat-icon>
  <h2>{{ (!!user ? user.name : 'Create consultant') }}</h2>
</div>

<div class="flex-row">
  <div class="flex-col-6">
    <mat-card>
      <mat-card-title>Information</mat-card-title>
      <form [formGroup]="userForm">
        <mat-form-field class="full-width">
          <input matInput placeholder="Name" required formControlName="name">
        </mat-form-field>
        <mat-form-field class="full-width">
          <input matInput placeholder="Email" type="email" required formControlName="email">
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Access level</mat-label>
          <mat-select formControlName="accessLevel" required>
            <mat-option value="1">Admin</mat-option>
            <mat-option value="0">Consultant</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="!user" class="full-width">
          <input matInput placeholder="Password" type="password" required formControlName="password">
        </mat-form-field>
      </form>
      <button *ngIf="!!user" mat-raised-button color="warn" class="small" (click)="delete()">Delete</button>
      <button mat-raised-button color="primary" (click)="submit()" [disabled]="!userForm.valid" class="small" style="margin-left:10px">{{ (!!user ? 'Update' : 'Create') }}</button>
    </mat-card>
  </div>
  <div class="flex-col-6">
    <mat-card>
      <mat-card-title>Clients</mat-card-title>

      <span *ngIf="!clients || clients.length == 0">No clients created</span>

      <div class="clients">
        <mat-list>
          <mat-list-item *ngFor="let client of clients">
            <div class="item">
              <mat-icon (click)="editClient(client.data.id)">edit</mat-icon>
              <mat-checkbox color="primary" [checked]="client.selected" (change)="addRemovePermission($event.checked, client.data.id)">{{client.data.name}}</mat-checkbox>
            </div>
          </mat-list-item>
        </mat-list>
      </div>
    </mat-card>
  </div>
</div>
