import { Injectable, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

export enum DialogSize {
  Small = 'dialog-small',
  Medium = 'dialog-medium',
  Fullscreen = 'dialog-fullscreen',
}

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog: MatDialog) { }


  public open(componentType: Type<any>, size: DialogSize = DialogSize.Small, data?: any): Observable<any> {
    const dialogRef = this.dialog.open(componentType, {
      panelClass: size,
      data: data
    });

    return dialogRef.afterClosed();
  }
}
