import { Component } from '@angular/core';
import { Subscription } from 'rxjs';

import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

  public show: boolean = false;

  private loaderSubscription: Subscription;


  constructor(private loaderService: LoaderService) { }


  ngOnInit(): void {
    this.loaderSubscription = this.loaderService.onChange.subscribe(show => this.show = show);
  }

  ngOnDestroy(): void {
    this.loaderSubscription.unsubscribe();
  }
}
