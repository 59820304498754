<mat-card>
  <button mat-raised-button color="primary" (click)="createUser()">Create</button>

  <div class="table-container">
    <table mat-table [dataSource]="data" matSort matSortActive="dateCreated" matSortDisableClear matSortDirection="desc">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let row">
          <a (click)="editUser(row.id)">{{row.name}}</a>
        </td>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let row">{{row.email}}</td>
      </ng-container>

      <!-- Access Level Column -->
      <ng-container matColumnDef="accessLevel">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Access level</th>
        <td mat-cell *matCellDef="let row">{{(row.accessLevel === 1 ? 'Admin' : 'Consultant')}}</td>
      </ng-container>

      <!-- Last Login Column -->
      <ng-container matColumnDef="lastLogin">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Last login at</th>
        <td mat-cell *matCellDef="let row">{{row.lastLogin | date}}</td>
      </ng-container>

      <!-- Created Column -->
      <ng-container matColumnDef="dateCreated">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created at</th>
        <td mat-cell *matCellDef="let row">{{row.dateCreated | date}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <mat-paginator [length]="resultsLength" [pageSize]="30"></mat-paginator>
</mat-card>
