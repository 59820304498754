import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BaseHttpService {

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8'
    })
  };

  protected fileUploadHttpOptions = {
    headers: new HttpHeaders()
  };

//  {
//  'Content-Type': 'multipart/form-data'
//}

  protected setAuthorizationToken(token: string, isFileUploadRequest: boolean = false): void {
    if (!!isFileUploadRequest) {
      if (!this.fileUploadHttpOptions.headers.has('Access-Token')) {
        this.fileUploadHttpOptions.headers = this.fileUploadHttpOptions.headers.set('Access-Token', token);
      }
    }
    else {
      if (!this.httpOptions.headers.has('Access-Token')) {
        this.httpOptions.headers = this.httpOptions.headers.set('Access-Token', token);
      }
    }
  }
}
