<div class="flex-row">
  <div class="flex-col-4">
    <mat-card>
      <mat-card-title>Clients</mat-card-title>
      <mat-card-subtitle>{{clients.length}} of {{totalClients}} records</mat-card-subtitle>
      <input type="text" class="search" placeholder="Search" (keyup)="searchClients($event)">
      <mat-list [ngClass]="!hasAccess(2) && !hasAccess(1) ? 'no-access' : ''">
        <mat-list-item *ngFor="let client of clients" class="selectable" (click)="selectClient(client.data.id)" [ngClass]="{ 'selected': client.selected }">
          <div class="item-content">
            <div class="title">{{client.data.name}}</div>
            <div class="actions">
              <mat-icon (click)="editClient(client.data.id)">edit</mat-icon>
              <mat-icon (click)="deleteClient(client.data.id)">delete_outline</mat-icon>
            </div>
          </div>
        </mat-list-item>
      </mat-list>
      <mat-card-actions *ngIf="!!hasAccess(2) || !!hasAccess(1)">
        <button mat-raised-button color="primary" (click)="createClient()" class="small">Add</button>
      </mat-card-actions>
    </mat-card>
  </div>
  <div class="flex-col-4">
    <mat-card>
      <mat-card-title>Reports</mat-card-title>
      <mat-card-subtitle>{{reports.length}} of {{totalReports}} records</mat-card-subtitle>
      <input type="text" class="search" placeholder="Search" (keyup)="searchReports($event)">
      <mat-list [ngClass]="!hasAccess(2) && !hasAccess(1) ? 'no-access' : ''">
        <mat-list-item *ngFor="let report of reports">
          <div class="item-content">
            <div class="title">{{report.data.name}}</div>
            <div class="actions">
              <mat-icon (click)="editReport(report.data.id)">edit</mat-icon>
              <mat-icon (click)="deleteReport(report.data.id)">delete_outline</mat-icon>
            </div>
          </div>
        </mat-list-item>
      </mat-list>
      <mat-card-actions *ngIf="!!hasAccess(2) || !!hasAccess(1)">
        <button mat-raised-button color="primary" (click)="createReport()" class="small">Add</button>
      </mat-card-actions>
    </mat-card>
  </div>
  <div class="flex-col-4">
    <mat-card *ngIf="!!hasAccess(2) || !!hasAccess(1)">
      <mat-card-title>Permissions</mat-card-title>
      <mat-card-subtitle>{{consultants.length}} of {{totalConsultants}} records</mat-card-subtitle>
      <input type="text" class="search" placeholder="Search" (keyup)="searchConsultants($event)">
      <mat-list>
        <mat-list-item *ngFor="let consultant of consultants">
          <mat-checkbox color="primary" [checked]="consultant.selected" (change)="addRemovePermission($event.checked, consultant.data.id)" [disabled]="!this.selectedClient">{{consultant.data.name}}</mat-checkbox>
        </mat-list-item>
      </mat-list>
      <mat-card-actions>
        <button mat-raised-button color="primary" (click)="createConsultant()" class="small">Add</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
