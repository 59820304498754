import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseHttpService } from './base-http.service';
import { AuthService } from './auth.service';
import { ApiResponseModel } from '../models/api-response.model';
import { ReportModel } from '../models/report.model';
import { ReportRowModel } from '../models/report-row.model';
import { ReportCommentModel } from '../models/report-comment.model';
import { CreateUpdateReportModel } from '../models/create-update-report.model';
import { CreateUpdateReportCommentModel } from '../models/create-update-report-comment.model';
import { ReportImsProjectsResponse } from '../models/report-ims-project.model';
import { AddRemoveProjectModel } from '../models/add-remove-project.model';
import { DeleteReportModel } from '../models/delete-report.model';
import { ReportProwlyCampaignsResponse } from '../models/report-prowly-campaigns-response';
import { AddRemoveCampaignModel } from '../models/add-remove-campaign.model';
import { ReportProwlyNewsroomsResponse } from '../models/report-prowly-newsroom-response';
import { ReportProwlyStoryResponse } from '../models/report-prowly-story-response';
import { AddPressReleaseModel } from '../models/add-press-release.model';
import { RemovePressReleaseModel } from '../models/remove-press-release.model';
import { ReportProwlyPressRelease } from '../models/report-prowly-press-release.model';

@Injectable({
  providedIn: 'root'
})
export class ReportService extends BaseHttpService {


  constructor(private authService: AuthService, private httpClient: HttpClient) {
    super();
  }


  get(currentUserId: number, all: boolean, clientId: number = 0, page: number = 0, size: number = 10, sortField: string = '	name', sortDescending: boolean = false, searchTerm: string = ''): Observable<ApiResponseModel<Array<ReportModel>>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.get<ApiResponseModel<Array<ReportModel>>>(`/api/reports?uid=${currentUserId}&cid=${clientId}&all=${(!!all ? 1 : 0)}&p=${page}&s=${size}&sf=${sortField}&sd=${(!!sortDescending ? 1 : 0)}&t=${searchTerm}`, this.httpOptions);
  }

  getRows(reportId: number, all: boolean, page: number = 0, size: number = 10, sortField: string = '	name', sortDescending: boolean = false): Observable<ApiResponseModel<Array<ReportRowModel>>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.get<ApiResponseModel<Array<ReportRowModel>>>(`/api/reports/${reportId}/rows?all=${(!!all ? 1 : 0)}&p=${page}&s=${size}&sf=${sortField}&sd=${(!!sortDescending ? 1 : 0)}`, this.httpOptions);
  }

  getComments(reportId: number): Observable<ApiResponseModel<Array<ReportCommentModel>>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.get<ApiResponseModel<Array<ReportCommentModel>>>(`/api/reports/${reportId}/comments`, this.httpOptions);
  }

  getSocialTalkProjects(): Observable<ReportImsProjectsResponse> {
    return this.httpClient.get<ReportImsProjectsResponse>(`https://apiq-clients.socialtalk.io/projects/4c41bab22725432491f338033e67385b?pageSize=-1`, this.httpOptions);
  }

  getProwlyCampaigns(): Observable<ReportProwlyCampaignsResponse> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.get<ReportProwlyCampaignsResponse>(`/api/reports/prowly/campaigns`, this.httpOptions);
  }

  getProwlyNewsrooms(): Observable<ReportProwlyNewsroomsResponse> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.get<ReportProwlyNewsroomsResponse>(`/api/reports/prowly/newsrooms`, this.httpOptions);
  }

  getProwlyStories(newsroomId: number, pageIndex: number, pageSize: number): Observable<ReportProwlyStoryResponse> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.get<ReportProwlyStoryResponse>(`/api/reports/prowly/stories/${newsroomId}/${pageIndex}/${pageSize}`, this.httpOptions);
  }

  getReport(id: number): Observable<ApiResponseModel<ReportModel>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.get<ApiResponseModel<ReportModel>>(`/api/reports/${id}`, this.httpOptions);
  }

  getReportPressReleases(id: number): Observable<ApiResponseModel<Array<ReportProwlyPressRelease>>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.get<ApiResponseModel<Array<ReportProwlyPressRelease>>>(`/api/reports/${id}/press-releases`, this.httpOptions);
  }

  create(data: CreateUpdateReportModel): Observable<ApiResponseModel<ReportModel>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.post<ApiResponseModel<ReportModel>>(`/api/reports/create`, data, this.httpOptions);
  }

  update(data: CreateUpdateReportModel): Observable<ApiResponseModel<string>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.post<ApiResponseModel<string>>(`/api/reports/update`, data, this.httpOptions);
  }

  delete(id: number): Observable<ApiResponseModel<DeleteReportModel>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.delete<ApiResponseModel<DeleteReportModel>>(`/api/reports/${id}`, this.httpOptions);
  }

  createComment(data: CreateUpdateReportCommentModel): Observable<ApiResponseModel<ReportCommentModel>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.post<ApiResponseModel<ReportCommentModel>>(`/api/reports/comments/create`, data, this.httpOptions);
  }

  updateComment(data: CreateUpdateReportCommentModel): Observable<ApiResponseModel<string>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.post<ApiResponseModel<string>>(`/api/reports/comments/update`, data, this.httpOptions);
  }

  deleteComment(id: number): Observable<ApiResponseModel<boolean>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.delete<ApiResponseModel<boolean>>(`/api/reports/comments/${id}`, this.httpOptions);
  }

  uploadRows(reportId: number, formData: FormData): Observable<ApiResponseModel<string>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token, true);
    return this.httpClient.post<ApiResponseModel<string>>(`/api/reports/${reportId}/upload-parse`, formData, this.fileUploadHttpOptions);
  }

  deleteRow(id: number): Observable<ApiResponseModel<boolean>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.delete<ApiResponseModel<boolean>>(`/api/reports/rows/${id}`, this.httpOptions);
  }

  deleteAllRows(reportId: number): Observable<ApiResponseModel<boolean>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.delete<ApiResponseModel<boolean>>(`/api/reports/${reportId}/all-rows`, this.httpOptions);
  }

  addProject(data: AddRemoveProjectModel): Observable<ApiResponseModel<string>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.post<ApiResponseModel<string>>(`/api/reports/projects/add`, data, this.httpOptions);
  }

  removeProject(data: AddRemoveProjectModel): Observable<ApiResponseModel<string>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.post<ApiResponseModel<string>>(`/api/reports/projects/remove`, data, this.httpOptions);
  }

  addCampaign(data: AddRemoveCampaignModel): Observable<ApiResponseModel<string>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.post<ApiResponseModel<string>>(`/api/reports/campaigns/add`, data, this.httpOptions);
  }

  removeCampaign(data: AddRemoveCampaignModel): Observable<ApiResponseModel<string>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.post<ApiResponseModel<string>>(`/api/reports/campaigns/remove`, data, this.httpOptions);
  }

  addPressRelease(data: AddPressReleaseModel): Observable<ApiResponseModel<string>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.post<ApiResponseModel<string>>(`/api/reports/press-releases/add`, data, this.httpOptions);
  }

  removePressRelease(data: RemovePressReleaseModel): Observable<ApiResponseModel<string>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.post<ApiResponseModel<string>>(`/api/reports/press-releases/remove`, data, this.httpOptions);
  }
}
