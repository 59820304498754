import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';

import { MaterialModule } from '../../material.module';

import { ReportsComponent } from './reports.component';
import { ReportComponent } from './components/report/report.component';
import { CommentDialogComponent } from './components/comment-dialog/comment-dialog.component';
import { UploadRowsDialogComponent } from './components/upload-rows/upload-rows-dialog.component';
import { PressReleaseDialogComponent } from './components/press-release-dialog/press-release-dialog.component';


@NgModule({
  declarations: [
    ReportsComponent,
    ReportComponent,
    CommentDialogComponent,
    PressReleaseDialogComponent,
    UploadRowsDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    EditorModule
  ],
  entryComponents: [
    CommentDialogComponent,
    PressReleaseDialogComponent,
    UploadRowsDialogComponent
  ]
})
export class ReportsModule { }
