import { Component, Inject, ViewChild, ElementRef, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiResponseStatus } from '../../../core/models/api-response.model';

import { ReportService } from '../../../core/services/report.service';
import { ToasterService } from '../../../core/services/toaster.service';


@Component({
  selector: 'app-upload-rows-dialog',
  templateUrl: './upload-rows-dialog.component.html',
  styleUrls: ['./upload-rows-dialog.component.scss']
})
export class UploadRowsDialogComponent implements OnInit {

  @ViewChild('form', { static: false }) form: ElementRef;

  public loading: boolean = false;
  public uploadForm: FormGroup;

  private fileData: File = null;
  private reportId: number;


  constructor(
    public dialogRef: MatDialogRef<UploadRowsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private reportService: ReportService,
    private toasterService: ToasterService) {

    this.reportId = data;
  }


  ngOnInit() {
    this.uploadForm = new FormGroup({
      file: new FormControl('')
    });
  }

  fileProgress(fileInput: any): void {
    this.fileData = <File>fileInput.target.files[0];
  }
  
  submit(): void {
    if (!this.uploadForm.valid) {
      return;
    }

    const formData = new FormData();
    formData.append('file', this.fileData);

    this.loading = true;
    this.uploadForm.disable();

    this.reportService.uploadRows(this.reportId, formData)
      .subscribe(response => {
        if (response.status === ApiResponseStatus.OK) {
          this.toasterService.success('File uploaded and processed');
          this.dialogRef.close(true);
        }
        else {
          this.toasterService.danger('Failed to upload and process file');
        }

        this.loading = false;
        this.uploadForm.enable();
      });
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
}
