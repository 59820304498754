import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

import { ClientModel } from '../core/models/client.model';
import { ClientService } from '../core/services/client.service';
import { LoaderService } from '../core/services/loader.service';
import { AuthService } from '../core/services/auth.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements AfterViewInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  public displayedColumns: string[] = ['picture', 'name', 'code', 'dateCreated'];
  public data: ClientModel[] = [];
  public resultsLength = 0;


  constructor(
    private authService: AuthService,
    private clientService: ClientService,
    private loaderService: LoaderService,
    private router: Router) { }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.loaderService.show();

          return this.clientService.get(
            this.authService.getLoggedUser().id,
            false,
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.sort.active,
            this.sort.direction === 'desc');
        }),
        map(response => {
          this.loaderService.hide();
          this.resultsLength = response.totalItems;
          return response.data;
        }),
        catchError(() => {
          this.loaderService.hide();
          return observableOf([]);
        })
      ).subscribe(response => this.data = response);
  }

  createClient(): void {
    this.router.navigate([`/clients/add`]);
  }

  editClient(id: number): void {
    this.router.navigate([`/clients/${id}`]);
  }

  hasAccess(level: number): boolean {
    return this.authService.hasAccess(level);
  }
}
