import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './modules/login/login.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { ClientsComponent } from './modules/clients/clients.component';
import { ClientComponent } from './modules/clients/components/client/client.component';
import { ConsultantsComponent } from './modules/consultants/consultants.component';
import { ConsultantComponent } from './modules/consultants/components/consultant/consultant.component';
import { ReportsComponent } from './modules/reports/reports.component';

import { AuthGuard } from './modules/core/guards/auth.guard';
import { AdminAccessOnlyGuard } from './modules/core/guards/admin-access-only.guard';
import { ReportComponent } from './modules/reports/components/report/report.component';
import { NotFoundComponent } from './modules/not-found/not-found.component';


const routes: Routes = [{
  path: '',
  component: DashboardComponent,
  canActivate: [AuthGuard]
}, {
  path: 'not-found',
  component: NotFoundComponent
}, {
  path: 'login',
  component: LoginComponent
}, {
  path: 'clients',
  component: ClientsComponent,
  canActivate: [AuthGuard]
}, {
  path: 'clients/:id',
  component: ClientComponent,
  canActivate: [AuthGuard]
}, {
  path: 'consultants',
  component: ConsultantsComponent,
  canActivate: [AuthGuard]
}, {
  path: 'consultants/:id',
  component: ConsultantComponent,
  canActivate: [AuthGuard]
}, {
  path: 'reports',
  component: ReportsComponent,
  canActivate: [AuthGuard]
}, {
  path: 'reports/:id',
  component: ReportComponent,
  canActivate: [AuthGuard]
}
  /*,{ path: '**', component: PageNotFoundComponent }*/
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    AuthGuard,
    AdminAccessOnlyGuard
  ]
})
export class AppRoutingModule { }
