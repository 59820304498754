import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ReportCommentModel } from '../../../core/models/report-comment.model';
import { AuthService } from '../../../core/services/auth.service';
import { DialogService, DialogSize } from '../../../core/services/dialog.service';
import { LoaderService } from '../../../core/services/loader.service';
import { ReportService } from '../../../core/services/report.service';
import { ToasterService } from '../../../core/services/toaster.service';
import { ConfirmDialogComponent } from '../../../core/components/confirm-dialog/confirm-dialog.component';
import { ApiResponseStatus } from '../../../core/models/api-response.model';

@Component({
  selector: 'app-comment-dialog',
  templateUrl: './comment-dialog.component.html',
  styleUrls: ['./comment-dialog.component.scss']
})
export class CommentDialogComponent implements OnInit {

  public commentForm: FormGroup;
  public title: string = '';
  public comment: ReportCommentModel;
  public editorConfig: any = {
    height: 300,
    menubar: false,
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help wordcount'
    ],
    toolbar:
      'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | code | help'
  };

  private reportId: number;


  constructor(
    public dialogRef: MatDialogRef<CommentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private reportService: ReportService,
    private loaderService: LoaderService,
    private dialogService: DialogService,
    private toasterService: ToasterService) {

    this.title = !data.comment ? 'New comment' : 'Edit comment';
    this.reportId = data.reportId;
    this.comment = data.comment;
  }


  ngOnInit(): void {
    this.commentForm = new FormGroup({
      title: new FormControl(''),
      body: new FormControl(''),
      page: new FormControl('')
    });

    if (!!this.comment) {
      this.commentForm.patchValue({
        title: this.comment.title,
        body: this.comment.body,
        page: this.comment.page,
      });
    }
  }

  submit(): void {
    if (!this.commentForm.valid) {
      return;
    }

    let data = this.commentForm.value;

    data.reportId = this.reportId;
    data.userId = this.authService.getLoggedUser().id;

    if (!data.title) {
      data.title = '-';
    }

    if (!data.body) {
      data.body = '-';
    }

    if (!data.page) {
      data.page = 0;
    }

    this.loaderService.show();

    if (!!this.comment) {
      data.id = this.comment.id;

      this.reportService.updateComment(data)
        .subscribe(response => {
          if (response.status === ApiResponseStatus.OK) {
            this.toasterService.success('Comment updated');
            this.dialogRef.close(true);
          }
          else {
            this.toasterService.danger(`Failed to update comment - ${response.message}`);
          }

          this.loaderService.hide();
        });
    }
    else {
      this.reportService.createComment(data)
        .subscribe(response => {
          if (response.status === ApiResponseStatus.OK) {
            this.toasterService.success('Comment created');
            this.dialogRef.close(true);
          }
          else {
            this.toasterService.danger(`Failed to create comment - ${response.message}`);
          }

          this.loaderService.hide();
        });
    }
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  delete(): void {
    this.dialogService.open(ConfirmDialogComponent, DialogSize.Small, {
      title: 'Delete comment?',
      message: `Are you sure that you want to delete this comment?`
    }).subscribe(result => {
      if (!!result) {
        this.reportService.deleteComment(this.comment.id).subscribe(response => {
          if (!!response.data) {
            this.toasterService.success('Comment deleted');
            this.dialogRef.close(true);
          }
          else {
            this.toasterService.success('Error deleting comment');
          }
        });
      }
    });
  }
}
