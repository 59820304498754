import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseHttpService } from './base-http.service';
import { UserModel } from '../models/user.model';
import { ApiResponseModel, ApiResponseStatus } from '../models/api-response.model';
import { LoginModel } from '../models/login.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseHttpService {

  public user: UserModel;


  constructor(private httpClient: HttpClient) {
    super();
  }


  login(data: LoginModel): Observable<ApiResponseModel<UserModel>> {
    return this.httpClient.post<ApiResponseModel<UserModel>>(`/api/login`, data, this.httpOptions);
  }

  signOut(): void {
    this.user = null;
    this.save();
    window.location.href = '/';
  }

  isAuthenticated(): boolean {
    const user = this.getLoggedUser();
    return !!user;
  }

  hasAccess(level: number): boolean {
    return !!this.user ? this.user.accessLevel === level : false;
  }

  getLoggedUser(): UserModel {
    let userData = localStorage.getItem('udat');

    if (!userData) {
      return null;
    }

    try {
      this.user = JSON.parse(atob(userData));
    }
    catch{

    }

    return this.user;
  }


  save(): void {
    if (!this.user) {
      localStorage.removeItem('udat');
      return;
    }

    localStorage.setItem('udat', btoa(JSON.stringify(this.user)));
  }
}
