import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(private snackBar: MatSnackBar) { }


  public information(message: string): void {
    this.open(message, 'information');
  }

  public success(message: string): void {
    this.open(message, 'success');
  }

  public danger(message: string): void {
    this.open(message, 'danger');
  }

  public warning(message: string): void {
    this.open(message, 'warning');
  }


  private open(message: string, type: string): void {
    this.snackBar.open(message, null, {
      duration: 5000,
      panelClass: 'snackbar-' + type
    });
  }
}
