import { AfterViewInit } from '@angular/core';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ApiResponseStatus } from '../../../core/models/api-response.model';
import { ReportProwlyNewsroom, ReportProwlyNewsroomsResponse } from '../../../core/models/report-prowly-newsroom-response';
import { ReportProwlyPressRelease } from '../../../core/models/report-prowly-press-release.model';
import { ReportProwlyStory, ReportProwlyStoryResponse } from '../../../core/models/report-prowly-story-response';
import { LoaderService } from '../../../core/services/loader.service';
import { ReportService } from '../../../core/services/report.service';
import { ToasterService } from '../../../core/services/toaster.service';


@Component({
  selector: 'app-press-release-dialog',
  templateUrl: './press-release-dialog.component.html',
  styleUrls: ['./press-release-dialog.component.scss']
})
export class PressReleaseDialogComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  public newsrooms: Array<ReportProwlyNewsroom> = [];
  public stories: Array<ReportProwlyStory> = [];
  public pageSize: number = 25;
  public totalStories: number = 0;
  public displayedStoriesColumns = ['name'];

  public get isValid(): boolean {
    return !!this.selectedNewsroom && this.selectedStories.length > 0;
  }

  private reportId: number;
  private selectedNewsroom: ReportProwlyNewsroom;
  private selectedStories: Array<ReportProwlyStory> = [];


  constructor(
    public dialogRef: MatDialogRef<PressReleaseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private reportService: ReportService,
    private loaderService: LoaderService,
    private toasterService: ToasterService) {

    this.reportId = data;
  }


  ngOnInit(): void {
    this.reportService.getProwlyNewsrooms()
      .subscribe((response: ReportProwlyNewsroomsResponse) => {
        this.newsrooms = response.journals;
      });
  }

  ngAfterViewInit(): void {
    this.paginator.page.subscribe(p => {
      this.onNewsroomChange(null, false);
    });
  }

  onNewsroomChange(newsroom?: ReportProwlyNewsroom, resetTotalStories?: boolean): void {
    if (!newsroom && !this.selectedNewsroom) {
      return;
    }

    if (!!newsroom) {
      this.selectedNewsroom = newsroom;
    }

    this.stories = [];

    if (!!resetTotalStories) {
      this.totalStories = 0;
    }

    this.loaderService.show();

    this.reportService.getProwlyStories(this.selectedNewsroom.id, this.paginator.pageIndex + 1, this.pageSize)
      .subscribe((response: ReportProwlyStoryResponse) => {
        this.loaderService.hide();

        this.totalStories = response.total;

        this.stories = response.stats;
      });
  }

  onStorySelectionChange(add: boolean, story: ReportProwlyStory): void {
    const index = this.selectedStories.findIndex(s => s.id == story.id);

    if (!!add) {
      if (index == -1) {
        this.selectedStories.push(story);
      }
    }
    else if (index > -1) {
      this.selectedStories.splice(index, 1);
    }
  }

  isStorySelected(storyId: number): boolean {
    return !!this.selectedStories.find(s => s.id == storyId);
  }

  submit(): void {
    if (!this.selectedNewsroom || this.selectedStories.length == 0) {
      return;
    }

    this.loaderService.show();

    this.associate(0);
  }

  cancel(): void {
    this.dialogRef.close(null);
  }


  private associate(index: number, added?: Array<any>): void {
    if (index > this.selectedStories.length - 1) {
      this.loaderService.hide();

      this.toasterService.success('Press releases associated');

      this.dialogRef.close(added);

      return;
    }

    const data = {
      reportId: this.reportId,
      journalId: this.selectedNewsroom.id,
      journalName: this.selectedNewsroom.name,
      journalDomain: this.selectedNewsroom.domain,
      storyId: this.selectedStories[index].id,
      storyName: this.selectedStories[index].title
    };

    this.reportService.addPressRelease(data)
      .subscribe(response => {
        if (response.status === ApiResponseStatus.OK) {
          if (!added) {
            added = [];
          }

          added.push(data);

          this.associate(index + 1, added);
        }
        else {
          this.loaderService.hide();

          this.toasterService.danger('Failed to associate press releases');
        }
      });
  }
}
