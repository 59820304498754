import { Component } from '@angular/core';

import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {


  constructor(private authService: AuthService) { }


  getUserName(): string {
    return !!this.authService.isAuthenticated() ? this.authService.user.name.split(' ')[0] : '';
  }

  hasAccess(level: number): boolean {
    return this.authService.hasAccess(level);
  }

  signOut(): void {
    this.authService.signOut();
  }
}
