<mat-card>
  <mat-card-title>
    <img src="/assets/logo_red.png" />
  </mat-card-title>
  <form [formGroup]="loginForm" (ngSubmit)="login($event)">
    <mat-form-field class="full-width">
      <input matInput placeholder="Email" type="email" required formControlName="email">
    </mat-form-field>
    <mat-form-field class="full-width">
      <input matInput placeholder="Password" type="password" required formControlName="password">
    </mat-form-field>
    <button mat-raised-button color="primary" class="full-width" type="submit" [disabled]="!loginForm.valid">Login</button>
  </form>
  <div *ngIf="!!authFailed" class="failed">Incorrect username or password</div>
</mat-card>

<app-loader></app-loader>
