<mat-card>
  <button *ngIf="hasAccess(2) || hasAccess(1)" mat-raised-button color="primary" (click)="createClient()">Create</button>

  <div class="table-container">
    <table mat-table [dataSource]="data" matSort matSortActive="dateCreated" matSortDisableClear matSortDirection="desc">
      <!-- Picture Column -->
      <ng-container matColumnDef="picture">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let row">
          <a (click)="editClient(row.id)">{{row.name}}</a>
        </td>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
        <td mat-cell *matCellDef="let row">{{row.code}}</td>
      </ng-container>

      <!-- Created Column -->
      <ng-container matColumnDef="dateCreated">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created at</th>
        <td mat-cell *matCellDef="let row">{{row.dateCreated | date}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <mat-paginator [length]="resultsLength" [pageSize]="30"></mat-paginator>
</mat-card>
