<mat-sidenav-container *ngIf="!!isAuthenticated()" class="main-container">
  <mat-sidenav-content>
    <app-toolbar></app-toolbar>

    <main>
      <router-outlet></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>

<main *ngIf="!isAuthenticated()" class="login-container">
  <router-outlet></router-outlet>
</main>
