<h1 mat-dialog-title>
  <span [innerHtml]="title"></span>
</h1>
<div mat-dialog-content>
  <form [formGroup]="commentForm">
    <mat-form-field class="full-width">
      <input matInput placeholder="Title" formControlName="title">
    </mat-form-field>

    <!--<mat-form-field class="full-width">
        <textarea matInput placeholder="Body" required formControlName="body" rows="6"></textarea>
    </mat-form-field>-->
    <div class="editor-title">Body</div>
    <editor class="editor" apiKey="ciux3r0tshv7q6qd6qjthh63ozdu1zpdvf22tfyy00l15n8r" [init]="editorConfig" required formControlName="body"></editor>

    <mat-form-field class="full-width">
      <input matInput placeholder="Page" type="number" formControlName="page">
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="cancel()" class="small">Cancel</button>
  <button *ngIf="!!comment" mat-raised-button (click)="delete()" color="danger" class="small">Delete</button>
  <button mat-raised-button color="primary" (click)="submit()" class="small">{{(!comment ? 'Create' : 'Update')}}</button>
</div>
