<h1 mat-dialog-title>Associate press release</h1>
<div mat-dialog-content>
  <mat-form-field class="full-width">
    <mat-label>Newsroom</mat-label>
    <mat-select (selectionChange)="onNewsroomChange($event.value)">
      <mat-option *ngFor="let newsroom of newsrooms" [value]="newsroom">{{newsroom.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <div class="table-container">
    <table mat-table class="full-width" [dataSource]="stories">
      <!-- Campaign Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Story</th>
        <td mat-cell *matCellDef="let story">
          <mat-checkbox color="primary" [checked]="isStorySelected(story.id)" (change)="onStorySelectionChange($event.checked, story)">
            {{story.title}}
          </mat-checkbox>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedStoriesColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedStoriesColumns;"></tr>
    </table>
  </div>

  <mat-paginator [pageSize]="pageSize" [hidePageSize]="true" [length]="totalStories" showFirstLastButtons></mat-paginator>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="cancel()" class="small">Cancel</button>
  <button mat-raised-button color="primary" (click)="submit()" class="small" [disabled]="!isValid">Associate</button>
</div>
