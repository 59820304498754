import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ApiResponseStatus } from '../core/models/api-response.model';

import { AuthService } from '../core/services/auth.service';
import { LoaderService } from '../core/services/loader.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    
  public loginForm: FormGroup;
  public authFailed: boolean;


  constructor(
    private authService: AuthService,
    private loaderService: LoaderService) { }


  ngOnInit(): void {
    this.loginForm = new FormGroup({
      email: new FormControl(''),
      password: new FormControl('')
    });
  }

  login(e: Event): void {
    if (!this.loginForm.valid) {
      return;
    }

    this.loaderService.show();

    this.authFailed = false;

    e.preventDefault();
    e.stopImmediatePropagation();

    this.authService.login(this.loginForm.value)
      .subscribe(response => {
        this.loaderService.hide();

        if (response.status === ApiResponseStatus.OK) {
          this.authService.user = response.data;
          this.authService.save();
          window.location.href = '/';
        }
        else {
          this.authFailed = true;
        }
      });;
  }

}
