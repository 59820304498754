import { Component, AfterViewInit, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';

import { Subscription, merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

import { ToasterService } from '../../../core/services/toaster.service';
import { ClientService } from '../../../core/services/client.service';
import { DialogService, DialogSize } from '../../../core/services/dialog.service';
import { ApiResponseModel, ApiResponseStatus } from '../../../core/models/api-response.model';
import { ReportModel } from '../../../core/models/report.model';
import { ReportRowModel } from '../../../core/models/report-row.model';
import { ReportCommentModel } from '../../../core/models/report-comment.model';
import { ClientModel } from '../../../core/models/client.model';
import { CommentDialogComponent } from '../comment-dialog/comment-dialog.component';
import { UploadRowsDialogComponent } from '../upload-rows/upload-rows-dialog.component';
import { ReportImsProjectModel } from '../../../core/models/report-ims-project.model';
import { AuthService } from '../../../core/services/auth.service';
import { AccessLevel } from '../../../core/models/access-level.model';
import { ReportType } from '../../../core/models/report-type.model';
import { ConfirmDialogComponent } from '../../../core/components/confirm-dialog/confirm-dialog.component';
import { SelectableModel } from '../../../core/models/selectable.model';
import { ReportService } from '../../../core/services/report.service';
import { LoaderService } from '../../../core/services/loader.service';
import { ReportProwlyCampaign, ReportProwlyCampaignsResponse } from '../../../core/models/report-prowly-campaigns-response';
import { ReportProwlyPressRelease } from '../../../core/models/report-prowly-press-release.model';
import { PressReleaseDialogComponent } from '../press-release-dialog/press-release-dialog.component';


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('rowsSort', { static: false }) rowsSort: MatSort;
  @ViewChild('projectsSort', { static: false }) projectsSort: MatSort;
  @ViewChild('campaignsSort', { static: false }) campaignsSort: MatSort;

  public report: ReportModel;
  public reportForm: FormGroup;
  public displayedColumns: string[] = ['title', 'theme', 'brand', 'origin', 'year', 'month', 'delete'];
  public displayedProjectsColumns: string[] = ['project', 'brand'];
  public displayedCampaignsColumns: string[] = ['name'];
  public displayedPressReleasesColumns: string[] = ['details', 'remove'];
  public data: ReportRowModel[] = [];
  public resultsLength = 0;
  public comments: Array<ReportCommentModel> = [];
  public clients: Array<ClientModel> = [];
  public filteredSocialTalkProjects: Array<SelectableModel<ReportImsProjectModel>> = [];
  public filteredProwlyCampaignsIds: Array<SelectableModel<ReportProwlyCampaign>> = [];
  public reportPressReleases: Array<ReportProwlyPressRelease> = [];

  private socialTalkProjects: Array<SelectableModel<ReportImsProjectModel>> = [];
  private prowlyCampaignsIds: Array<SelectableModel<ReportProwlyCampaign>> = [];
  private routeParamMapSubscription: Subscription;
  private clientCode: string = null;
  private reportType: string = null;


  constructor(
    private authService: AuthService,
    private clientService: ClientService,
    private reportService: ReportService,
    private loaderService: LoaderService,
    private toasterService: ToasterService,
    private dialogService: DialogService,
    private router: Router,
    private route: ActivatedRoute) {
  }


  ngOnInit(): void {
    this.reportForm = new FormGroup({
      clientId: new FormControl(''),
      type: new FormControl(''),
      theme: new FormControl(''),
      name: new FormControl({ value: '', disabled: true }),
      description: new FormControl(''),
      date: new FormControl(''),
      pbiUrl: new FormControl('')
    });
  }

  ngAfterViewInit() {
    this.routeParamMapSubscription = this.route.paramMap.subscribe(params => {
      this.loaderService.show();

      if (params.get('id') !== 'add') {
        this.reportService.getReport(parseInt(params.get('id'))).subscribe(response => {
          if (response.status === ApiResponseStatus.OK) {
            this.report = response.data;

            this.reportForm.patchValue({
              clientId: this.report.clientId,
              type: this.report.type.toString(),
              theme: this.report.theme,
              name: this.report.name,
              description: this.report.description,
              date: this.report.date,
              pbiUrl: this.report.pbiUrl
            });

            if (this.authService.getLoggedUser().accessLevel === AccessLevel.CONSULTANT) {
              this.reportForm.disable();
            }

            this.getReportRows();
            this.getClients();
            this.getReportComments();
            this.getSocialTalkProjects();
            this.getProwlyCampaigns();
            this.getPressReleases();
            this.getClient(this.report.clientId);
            this.setReportType(this.report.type);
          }
          else {
            this.router.navigate(['/not-found']);
          }
        });
      }
      else {
        if (this.authService.getLoggedUser().accessLevel === AccessLevel.ADMIN) {
          this.getClients();
          this.getSocialTalkProjects();
        }
        else {
          this.loaderService.hide();
          this.router.navigate(['/not-found']);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.routeParamMapSubscription.unsubscribe();
  }

  submit(): void {
    if (!this.reportForm.valid) {
      return;
    }

    let data = this.reportForm.value;

    if (!!this.clientCode && !!this.reportType) {
      if (!(data.date instanceof Date)) {
        data.date = new Date(data.date);
      }

      const theme = data.theme.toUpperCase().split(' ').join('-');
      data.name = `${this.clientCode.toUpperCase()}-${this.reportType.toUpperCase()}-${theme}-${data.date.getFullYear()}`;
    }

    data.imsProjects = this.socialTalkProjects.filter(p => !!p.selected).map(p => p.data.id);

    this.loaderService.show();

    if (!!this.report) {
      data.id = this.report.id;

      this.reportService.update(data)
        .subscribe(response => {
          this.loaderService.hide();

          if (response.status === ApiResponseStatus.OK) {
            this.toasterService.success("Report updated");
          }
          else {
            this.toasterService.danger("Error updating report - " + response.message);
          }
        });
    }
    else {
      this.reportService.create(data)
        .subscribe(response => {
          this.loaderService.hide();

          if (response.status === ApiResponseStatus.OK) {
            this.toasterService.success("Report created");
            this.router.navigate([`/reports/${response.data.id}`]);
          }
          else {
            this.toasterService.danger("Error creating report - " + response.message);
          }
        });
    }
  }

  back(): void {
    this.router.navigate(['/reports']);
  }

  onClientChange(event: any): void {
    this.getClient(event.value);
  }

  onTypeChange(event: any): void {
    this.setReportType(parseInt(event.value));
  }

  createComment(): void {
    this.dialogService.open(CommentDialogComponent, DialogSize.Small, {
      reportId: this.report.id
    }).subscribe(result => {
      if (!!result) {
        this.getReportComments(true);
      }
    });
  }

  editComment(id: number): void {
    this.dialogService.open(CommentDialogComponent, DialogSize.Small, {
      reportId: this.report.id,
      comment: this.comments.find(c => c.id === id)
    }).subscribe(result => {
      if (!!result) {
        this.getReportComments(true);
      }
    });
  }

  uploadRows(): void {
    this.dialogService.open(UploadRowsDialogComponent, DialogSize.Small, this.report.id)
      .subscribe(result => {
        if (!!result) {
          this.rowsSort.sortChange.emit();
        }
      });
  }

  addRemoveProject(add: boolean, projectId: number): void {
    if (!this.report) {
      return;
    }

    const project = this.socialTalkProjects.find(c => c.data.id === projectId);
    project.selected = add;

    if (!!add) {
      this.reportService.addProject({
        projectId: projectId,
        reportId: this.report.id
      })
        .subscribe(response => {
          if (response.status === ApiResponseStatus.OK) {
            this.toasterService.success('Project added');
          }
          else {
            this.toasterService.danger('Failed to add project');
          }
        });
    }
    else {
      this.reportService.removeProject({
        projectId: projectId,
        reportId: this.report.id
      })
        .subscribe(response => {
          if (response.status === ApiResponseStatus.OK) {
            this.toasterService.success('Project removed');
          }
          else {
            this.toasterService.danger('Failed to remove project');
          }
        });
    }
  }

  addRemoveCampaign(add: boolean, campaignId: number): void {
    if (!this.report) {
      return;
    }

    const campaign = this.prowlyCampaignsIds.find(c => c.data.id === campaignId);
    campaign.selected = add;

    if (!!add) {
      this.reportService.addCampaign({
        campaignId: campaignId,
        reportId: this.report.id
      })
        .subscribe(response => {
          if (response.status === ApiResponseStatus.OK) {
            this.toasterService.success('Campaign added');
          }
          else {
            this.toasterService.danger('Failed to add campaign');
          }
        });
    }
    else {
      this.reportService.removeCampaign({
        campaignId: campaignId,
        reportId: this.report.id
      })
        .subscribe(response => {
          if (response.status === ApiResponseStatus.OK) {
            this.toasterService.success('Campaign removed');
          }
          else {
            this.toasterService.danger('Failed to remove campaign');
          }
        });
    }
  }

  addPressRelease(): void {
    if (!this.report) {
      return;
    }

    this.dialogService.open(PressReleaseDialogComponent, DialogSize.Medium, this.report.id)
      .subscribe(result => {
        if (!!result) {
          this.getPressReleases();
        }
      });
  }

  deletePressRelease(pressRelease: ReportProwlyPressRelease): void {
    this.reportService.removePressRelease({
      journalId: pressRelease.journalId,
      storyId: pressRelease.storyId,
      reportId: this.report.id
    })
      .subscribe(response => {
        if (response.status === ApiResponseStatus.OK) {
          this.getPressReleases();

          this.toasterService.success('Press release removed');
        }
        else {
          this.toasterService.danger('Failed to remove press release');
        }
      });
  }

  hasAccess(level: number): boolean {
    return this.authService.hasAccess(level);
  }

  deleteReport(): void {
    this.dialogService.open(ConfirmDialogComponent, DialogSize.Small, {
      title: 'Delete report?',
      message: `Are you sure that you want to delete this report?`
    }).subscribe(result => {
      if (!!result) {
        this.reportService.delete(this.report.id).subscribe(response => {
          if (!!response.data) {
            this.toasterService.success('Report deleted');
            this.router.navigate(['/reports']);
          }
          else {
            this.toasterService.danger('Error deleting report');
          }
        });
      }
    });
  }

  deleteRow(id: number): void {
    this.dialogService.open(ConfirmDialogComponent, DialogSize.Small, {
      title: 'Delete row?',
      message: `Are you sure that you want to delete this row?`
    }).subscribe(result => {
      if (!!result) {
        this.reportService.deleteRow(id).subscribe(response => {
          if (!!response.data) {
            this.toasterService.success('Row deleted');
            this.getReportRows();
          }
          else {
            this.toasterService.danger('Error deleting row');
          }
        });
      }
    });
  }

  deleteAllRows(): void {
    this.dialogService.open(ConfirmDialogComponent, DialogSize.Small, {
      title: 'Delete all rows?',
      message: `Are you sure that you want to delete all rows?`
    }).subscribe(result => {
      if (!!result) {
        this.reportService.deleteAllRows(this.report.id).subscribe(response => {
          if (!!response.data) {
            this.toasterService.success('Rows deleted');
            this.getReportRows();
          }
          else {
            this.toasterService.danger('Error deleting rows');
          }
        });
      }
    });
  }

  filterProjects(event: any): void {
    this.filteredSocialTalkProjects = !event.target.value
      ? this.socialTalkProjects
      : this.socialTalkProjects.filter(c => c.data.name.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1);
  }

  sortProjects(sort: Sort) {
    const data = this.filteredSocialTalkProjects.slice();

    if (!sort.active || sort.direction === '') {
      this.filteredSocialTalkProjects = data;

      return;
    }

    this.filteredSocialTalkProjects = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      const fieldA = sort.active == 'project' ? a.data.name : (!!a.data.brand ? a.data.brand.name : '');

      const fieldB = sort.active == 'project' ? b.data.name : (!!b.data.brand ? b.data.brand.name : '');

      return (fieldA < fieldB ? -1 : 1) * (isAsc ? 1 : -1);
    });
  }

  filterCampaigns(event: any): void {
    this.filteredProwlyCampaignsIds = !event.target.value
      ? this.prowlyCampaignsIds
      : this.prowlyCampaignsIds.filter(c => c.data.name.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1);
  }

  sortCampaigns(sort: Sort) {
    const data = this.filteredProwlyCampaignsIds.slice();

    if (!sort.active || sort.direction === '') {
      this.filteredProwlyCampaignsIds = data;

      return;
    }

    this.filteredProwlyCampaignsIds = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      const fieldA = sort.active == 'name' ? a.data.name : (!!a.data.description ? a.data.description : '');

      const fieldB = sort.active == 'name' ? b.data.name : (!!b.data.description ? b.data.description : '');

      return (fieldA < fieldB ? -1 : 1) * (isAsc ? 1 : -1);
    });
  }


  private getReportRows(): void {
    this.paginator.pageSize = 10;

    this.rowsSort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.rowsSort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.reportService.getRows(
            this.report.id,
            false,
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.rowsSort.active,
            this.rowsSort.direction === 'desc');
        }),
        map(response => {
          this.resultsLength = response.totalItems;
          return response.data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(response => this.data = response);
  }

  private getClients(): void {
    this.clientService.get(this.authService.getLoggedUser().id, true).subscribe(response => {
      if (response.status === ApiResponseStatus.OK) {
        this.clients = response.data;
      }
      else {
        this.toasterService.danger("Falied to retrieve clients list");
      }
    });
  }

  private getReportComments(showLoader: boolean = false): void {
    if (!!showLoader) {
      this.loaderService.show();
    }

    this.reportService.getComments(this.report.id).subscribe(response => {
      if (response.status === ApiResponseStatus.OK) {
        this.comments = response.data;
      }
      else {
        this.toasterService.danger("Falied to retrieve report comments list");
      }
      if (!!showLoader) {
        this.loaderService.hide();
      }
    });
  }

  private getSocialTalkProjects(): void {
    this.socialTalkProjects = [];

    this.filteredSocialTalkProjects = [];

    //this.socialTalkService.getProjects()
    this.reportService.getSocialTalkProjects()
      .subscribe(response => {
        if (!response.success) {
          return;
        }

        response.data.records.sort((a, b) => (a.name > b.name) ? 1 : -1)

        this.socialTalkProjects = response.data.records.map(p => {
          return {
            selected: !!this.report ? !!this.report.imsProjects.find(pId => pId === p.id) : false,
            data: p
          };
        });

        this.filteredSocialTalkProjects = this.socialTalkProjects;

        this.loaderService.hide();
      });
  }

  private getProwlyCampaigns(): void {
    this.prowlyCampaignsIds = [];

    this.filteredProwlyCampaignsIds = [];

    this.reportService.getProwlyCampaigns()
      .subscribe((response: ReportProwlyCampaignsResponse) => {
        this.prowlyCampaignsIds = response.email_campaigns.map(p => {
          return {
            selected: !!this.report ? !!this.report.prowlyCampaignsIds.find(pId => pId === p.id) : false,
            data: p
          };
        });

        this.filteredProwlyCampaignsIds = this.prowlyCampaignsIds;

        this.loaderService.hide();
      });
  }

  private getPressReleases(): void {
    this.reportPressReleases = [];

    this.loaderService.show();

    this.reportService.getReportPressReleases(this.report.id)
      .subscribe((response: ApiResponseModel<Array<ReportProwlyPressRelease>>) => {
        this.reportPressReleases = response.data;

        this.loaderService.hide();
      });
  }

  private getClient(id: number): void {
    this.clientService.getClient(id)
      .subscribe(response => {
        if (response.status === ApiResponseStatus.OK) {
          this.clientCode = response.data.code;
        }
        else {
          this.toasterService.danger("Erro ao ler cliente");
        }
      });
  }

  private setReportType(id: number): void {
    switch (id) {
      case ReportType.CISION:
        this.reportType = 'R1';
      case ReportType.CISION_IMS:
        this.reportType = 'R2';
      case ReportType.PROJECT:
        this.reportType = 'R3';
      case ReportType.CUSTOM:
        this.reportType = 'R4';
    }
  }
}
