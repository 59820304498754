import { Component, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { UserService } from '../../../core/services/user.service';
import { ToasterService } from '../../../core/services/toaster.service';
import { ClientService } from '../../../core/services/client.service';
import { UserModel } from '../../../core/models/user.model';
import { ApiResponseStatus } from '../../../core/models/api-response.model';
import { ClientModel } from '../../../core/models/client.model';
import { SelectableModel } from '../../../core/models/selectable.model';
import { AuthService } from '../../../core/services/auth.service';
import { LoaderService } from '../../../core/services/loader.service';
import { DialogService, DialogSize } from '../../../core/services/dialog.service';
import { AccessLevel } from '../../../core/models/access-level.model';
import { ConfirmDialogComponent } from '../../../core/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-consultant',
  templateUrl: './consultant.component.html',
  styleUrls: ['./consultant.component.scss']
})
export class ConsultantComponent implements OnInit, AfterViewInit, OnDestroy {

  public user: UserModel;
  public userForm: FormGroup;
  public clients: Array<SelectableModel<ClientModel>> = [];
  
  private routeParamMapSubscription: Subscription;


  constructor(
    private authService: AuthService,
    private userService: UserService,
    private clientService: ClientService,
    private loaderService: LoaderService,
    private toasterService: ToasterService,
    private dialogService: DialogService,
    private router: Router,
    private route: ActivatedRoute) {
  }


  ngOnInit(): void {
    this.userForm = new FormGroup({
      name: new FormControl(''),
      email: new FormControl(''),
      accessLevel: new FormControl(''),
      password: new FormControl('')
    });
    
    if (this.authService.getLoggedUser().accessLevel === AccessLevel.CONSULTANT) {
      this.router.navigate(['/not-found']);
    }
  }

  ngAfterViewInit() {
    if (this.authService.getLoggedUser().accessLevel === AccessLevel.CONSULTANT) {
      return;
    }

    this.routeParamMapSubscription = this.route.paramMap.subscribe(params => {
      this.loaderService.show();

      if (params.get('id') === 'add') {
        this.populateClientsList();
      }
      else {
        this.userForm.removeControl('password');

        this.userService.getUser(parseInt(params.get('id'))).subscribe(response => {
          if (response.status === ApiResponseStatus.OK) {
            this.user = response.data;

            this.userForm.patchValue({
              name: this.user.name,
              email: this.user.email,
              accessLevel: this.user.accessLevel.toString()
            });

            this.populateClientsList();
          }
          else {
            this.router.navigate(['/not-found']);
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    if (!!this.routeParamMapSubscription) {
      this.routeParamMapSubscription.unsubscribe();
    }
  }

  addRemovePermission(add: boolean, clientId: number): void {
    if (!this.user) {
      return;
    }

    const client = this.clients.find(c => c.data.id === clientId);
    client.selected = add;

    if (!!add) {
      this.clientService.addPermission({
        clientId: clientId,
        userId: this.user.id
      })
        .subscribe(response => {
          if (response.status === ApiResponseStatus.OK) {
            this.toasterService.success('Permission added');
          }
          else {
            this.toasterService.danger('Failed to add permission');
          }
        });
    }
    else {
      this.clientService.removePermission({
        clientId: clientId,
        userId: this.user.id
      })
        .subscribe(response => {
          if (response.status === ApiResponseStatus.OK) {
            this.toasterService.success('Permission removed');
          }
          else {
            this.toasterService.danger('Failed to remove permission');
          }
        });
    }
  }

  submit(): void {
    if (!this.userForm.valid) {
      return;
    }

    let data = this.userForm.value;

    data.clients = this.clients.filter(c => !!c.selected).map(c => c.data.id);

    this.loaderService.show();

    if (!!this.user) {
      data.id = this.user.id;

      this.userService.update(data)
        .subscribe(response => {
          this.loaderService.hide();

          if (response.status === ApiResponseStatus.OK) {
            this.toasterService.success("Consultant updated");
          }
          else {
            this.toasterService.danger("Error updating consultant - " + response.message);
          }
        });
    }
    else {
      this.userService.create(data)
        .subscribe(response => {
          this.loaderService.hide();

          if (response.status === ApiResponseStatus.OK) {
            this.toasterService.success("Consultant created");
            this.router.navigate([`/consultants/${response.data.id}`]);
          }
          else {
            this.toasterService.danger("Error creating consultant - " + response.message);
          }
        });
    }
  }

  delete(): void {
    this.dialogService.open(ConfirmDialogComponent, DialogSize.Small, {
      title: 'Delete consultant?',
      message: `Are you sure that you want to delete this consultant?`
    }).subscribe(result => {
      if (!!result) {
        this.userService.delete(this.user.id).subscribe(response => {
          if (!!response.data) {
            this.toasterService.success('Consultant deleted');
            this.router.navigate(['/consultants']);
          }
          else {
            this.toasterService.danger('Error deleting consultant');
          }
        });
      }
    });
  }

  editClient(id: number): void {
    this.router.navigate([`/clients/${id}`]);
  }

  back(): void {
    this.router.navigate(['/consultants']);
  }


  private populateClientsList(): void {
    this.clientService.get(this.authService.getLoggedUser().id, true).subscribe(response => {
      if (response.status === ApiResponseStatus.OK) {
        this.clients = response.data.map(c => {
          return {
            selected: !!this.user ? !!this.user.clients.find(uc => uc.id === c.id) : false,
            data: c
          };
        });
      }
      else {
        this.toasterService.danger("Falied to retrieve clients list");
      }

      this.loaderService.hide();
    });
  }
}
