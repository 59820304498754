<mat-toolbar role="heading" color="primary">
  <mat-toolbar-row>
    <div class="logo-container">
      <a routerLink="/">
        <!--<img src="/assets/logo.svg" />-->
        <img src="/assets/logo_red.png" />
      </a>
    </div>

    <div class="menu">
      <a routerLink="/clients" routerLinkActive="selected">Clients</a>
      <a routerLink="/reports" routerLinkActive="selected">Reports</a>
      <a *ngIf="!!hasAccess(2) || !!hasAccess(1)" routerLink="/consultants" routerLinkActive="selected">Consultants</a>
    </div>

    <span class="spacer"></span>

    <app-loader></app-loader>

    <div class="user" [matMenuTriggerFor]="account">
      <div class="name">Hi, <span>{{getUserName()}}</span></div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<mat-menu #account class="action-menu" xPosition="before">
  <mat-list role="list">
    <!--<mat-list-item role="listitem" class="action-menu-item travel">
      <mat-icon class="icon">face</mat-icon>
      <span>My profile</span>
      <mat-icon class="navigate">navigate_next</mat-icon>
    </mat-list-item>-->
    <mat-list-item role="listitem" class="action-menu-item sign-out">
      <button mat-raised-button color="warn" class="small full-width" (click)="signOut()">SIGN OUT</button>
    </mat-list-item>
  </mat-list>
</mat-menu>
