import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '../../material.module';

import { ClientsComponent } from './clients.component';
import { ClientComponent } from './components/client/client.component';



@NgModule({
  declarations: [
    ClientsComponent,
    ClientComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule
  ]
})
export class ClientsModule { }
