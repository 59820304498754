import { Component, AfterViewInit, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription, merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

import { UserService } from '../../../core/services/user.service';
import { ToasterService } from '../../../core/services/toaster.service';
import { ClientService } from '../../../core/services/client.service';
import { UserModel } from '../../../core/models/user.model';
import { ApiResponseStatus } from '../../../core/models/api-response.model';
import { ClientModel } from '../../../core/models/client.model';
import { SelectableModel } from '../../../core/models/selectable.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ReportModel } from '../../../core/models/report.model';
import { AuthService } from '../../../core/services/auth.service';
import { ReportService } from '../../../core/services/report.service';
import { LoaderService } from '../../../core/services/loader.service';
import { DialogService, DialogSize } from '../../../core/services/dialog.service';
import { AccessLevel } from '../../../core/models/access-level.model';
import { ConfirmDialogComponent } from '../../../core/components/confirm-dialog/confirm-dialog.component';
import { ReportType } from '../../../core/models/report-type.model';


@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  public client: ClientModel;
  public clientForm: FormGroup;
  public consultants: Array<SelectableModel<UserModel>> = [];
  public displayedColumns: string[] = ['type', 'name', 'date', 'theme', 'pbiUrl', 'dateCreated', 'delete'];
  public data: ReportModel[] = [];
  public resultsLength = 0;

  private routeParamMapSubscription: Subscription;


  constructor(
    private authService: AuthService,
    private userService: UserService,
    private clientService: ClientService,
    private reportService: ReportService,
    private loaderService: LoaderService,
    private dialogService: DialogService,
    private toasterService: ToasterService,
    private router: Router,
    private route: ActivatedRoute) {
  }


  ngOnInit(): void {
    this.clientForm = new FormGroup({
      name: new FormControl(''),
      code: new FormControl('')
    });
  }

  ngAfterViewInit() {
    this.routeParamMapSubscription = this.route.paramMap.subscribe(params => {
      this.loaderService.show();

      const loggerUser = this.authService.getLoggedUser();

      if (params.get('id') === 'add') {
        if (loggerUser.accessLevel === AccessLevel.ADMIN) {
          this.populateConsultantsList(true);
        }
        else {
          this.loaderService.hide();
          this.router.navigate(['/not-found']);
        }
      }
      else {
        if (loggerUser.accessLevel === AccessLevel.ADMIN) {
          this.getClient(parseInt(params.get('id')));
        }
        else {
          const id = parseInt(params.get('id'));

          this.clientService.canEdit({
            targetId: id,
            userId: loggerUser.id
          })
            .subscribe(response => {
              if (response.status === ApiResponseStatus.OK && !!response.data) {
                this.getClient(id);
              }
              else {
                this.router.navigate(['/not-found']);
              }

              this.loaderService.hide();
            });
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.routeParamMapSubscription.unsubscribe();
  }

  submit(): void {
    if (!this.clientForm.valid) {
      return;
    }

    let data = this.clientForm.value;

    data.users = this.consultants.filter(c => !!c.selected).map(c => c.data.id);

    this.loaderService.show();

    if (!!this.client) {
      data.id = this.client.id;

      this.clientService.update(data)
        .subscribe(response => {
          this.loaderService.hide();

          if (response.status === ApiResponseStatus.OK) {
            this.toasterService.success("Client updated");
          }
          else {
            this.toasterService.danger("Error updating client - " + response.message);
          }
        });
    }
    else {
      this.clientService.create(data)
        .subscribe(response => {
          this.loaderService.hide();

          if (response.status === ApiResponseStatus.OK) {
            this.toasterService.success("Client created");
            this.router.navigate([`/clients/${response.data.id}`]);
          }
          else {
            this.toasterService.danger("Error creating client - " + response.message);
          }
        });
    }
  }

  delete(): void {
    this.dialogService.open(ConfirmDialogComponent, DialogSize.Small, {
      title: 'Delete client?',
      message: `Are you sure that you want to delete this client?`
    }).subscribe(result => {
      if (!!result) {
        this.clientService.delete(this.client.id).subscribe(response => {
          if (!!response.data) {
            this.toasterService.success('Client deleted');
            this.router.navigate(['/clients']);
          }
          else {
            this.toasterService.success('Error deleting client');
          }
        });
      }
    });
  }

  deleteReport(id: number): void {
    this.dialogService.open(ConfirmDialogComponent, DialogSize.Small, {
      title: 'Delete report?',
      message: `Are you sure that you want to delete this report?`
    }).subscribe(result => {
      if (!!result) {
        this.reportService.deleteComment(id).subscribe(response => {
          if (!!response.data) {
            this.toasterService.success('Report deleted');
            this.router.navigate(['/reports']);
          }
          else {
            this.toasterService.danger('Error deleting report');
          }
        });
      }
    });
  }

  addRemovePermission(add: boolean, consultantId: number): void {
    if (!this.client) {
      return;
    }

    const consultant = this.consultants.find(c => c.data.id === consultantId);
    consultant.selected = add;

    if (!!add) {
      this.clientService.addPermission({
        clientId: this.client.id,
        userId: consultant.data.id
      })
        .subscribe(response => {
          if (response.status === ApiResponseStatus.OK) {
            this.toasterService.success('Permission added');
          }
          else {
            this.toasterService.danger('Failed to add permission');
          }
        });
    }
    else {
      this.clientService.removePermission({
        clientId: this.client.id,
        userId: consultant.data.id
      })
        .subscribe(response => {
          if (response.status === ApiResponseStatus.OK) {
            this.toasterService.success('Permission removed');
          }
          else {
            this.toasterService.danger('Failed to remove permission');
          }
        });
    }
  }

  back(): void {
    this.router.navigate(['/clients']);
  }

  getType(type: number): string {
    switch (type) {
      case ReportType.CISION: return 'CISION';
      case ReportType.CISION_IMS: return 'CISION & IMS';
      case ReportType.PROJECT: return 'PROJECT';
      default: return 'CUSTOM';
    }
  }

  createReport(): void {
    this.router.navigate(['/reports/add']);
  }

  editReport(id: number): void {
    this.router.navigate([`/reports/${id}`]);
  }

  hasAccess(level: number): boolean {
    return this.authService.hasAccess(level);
  }


  private getClient(id: number) {
    this.clientService.getClient(id).subscribe(response => {
      if (response.status === ApiResponseStatus.OK) {
        this.client = response.data;

        this.clientForm.patchValue({
          name: this.client.name,
          code: this.client.code
        });

        if (this.authService.getLoggedUser().accessLevel === AccessLevel.CONSULTANT) {
          this.clientForm.disable();
        }

        this.populateConsultantsList();
        this.getReports();
      }
      else {
        //goto to NOT FOUND PAGE
      }
    });
  }

  private populateConsultantsList(hideLoader: boolean = false): void {
    if (this.authService.getLoggedUser().accessLevel === AccessLevel.CONSULTANT) {
      this.loaderService.hide();
      return;
    }

    this.userService.get(true).subscribe(response => {
      if (response.status === ApiResponseStatus.OK) {
        this.consultants = response.data.map(c => {
          return {
            selected: !!this.client ? !!this.client.users.find(uc => uc.id === c.id) : false,
            data: c
          };
        });
      }
      else {
        this.toasterService.danger("Falied to retrieve consultants list");
      }

      if (!!hideLoader) {
        this.loaderService.hide();
      }
    });
  }

  private getReports(): void {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.loaderService.show();

          return this.reportService.get(
            -1,
            false,
            this.client.id,
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.sort.active,
            this.sort.direction === 'desc');
        }),
        map(response => {
          this.loaderService.hide();
          this.resultsLength = response.totalItems;
          return response.data;
        }),
        catchError(() => {
          this.loaderService.hide();
          return observableOf([]);
        })
      ).subscribe(response => this.data = response);
  }
}
