export enum ApiResponseStatus {
  OK = 'OK',
  NOK = 'NOK'
}

export class ApiResponseModel<T> {
  public status: ApiResponseStatus;
  public message?: string;
  public data?: T;
  public totalItems?: number;
}
