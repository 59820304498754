<div class="header">
  <mat-icon (click)="back()">arrow_back_ios</mat-icon>
  <h2>{{ (!!report ? report.name : 'Create report') }}</h2>
</div>

<div class="flex-row">
  <div class="flex-col-6">
    <mat-card>
      <mat-card-title>Information</mat-card-title>
      <form [formGroup]="reportForm" (ngSubmit)="submit()">
        <mat-form-field class="full-width">
          <input matInput placeholder="Name" formControlName="name">
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Client</mat-label>
          <mat-select formControlName="clientId" required (selectionChange)="onClientChange($event)">
            <mat-option *ngFor="let client of clients" [value]="client.id">{{client.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Type</mat-label>
          <mat-select formControlName="type" required (selectionChange)="onTypeChange($event)">
            <mat-option value="1">CISION</mat-option>
            <mat-option value="2">CISION &amp; SocialTalk</mat-option>
            <mat-option value="3">PROJECT</mat-option>
            <mat-option value="4">CUSTOM</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="full-width">
          <input matInput placeholder="Theme" required formControlName="theme">
        </mat-form-field>
        <mat-form-field>
          <input matInput [matDatepicker]="picker" placeholder="Choose a date" formControlName="date" required>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="full-width">
          <input matInput placeholder="PowerBI url" formControlName="pbiUrl">
        </mat-form-field>
        <mat-form-field class="full-width">
          <textarea matInput placeholder="Description" formControlName="description" rows="6"></textarea>
        </mat-form-field>
        <button *ngIf="!!report && !!hasAccess(1)" mat-raised-button color="warn" (click)="deleteReport()" class="small">Delete</button>
        <button *ngIf="!!hasAccess(1)" mat-raised-button color="primary" type="submit" [disabled]="!reportForm.valid" class="small" style="margin-left:10px">{{ (!!report ? 'Update' : 'Create') }}</button>
      </form>
    </mat-card>
  </div>
  <div class="flex-col-6">
    <mat-card>
      <mat-card-title>
        Comments
        <button *ngIf="!!report" mat-raised-button color="primary" class="small" (click)="createComment()">Add</button>
      </mat-card-title>
      <div class="comments">
        <div class="comment" *ngFor="let comment of comments" (click)="editComment(comment.id)">
          <div class="title">{{(!!comment.title ? comment.title : '-')}}</div>
          <div class="date">{{comment.dateModified}}</div>
          <div class="body" [innerHtml]="comment.body"></div>
        </div>
      </div>
    </mat-card>
    <mat-card>
      <mat-card-title>SocialTalk projects</mat-card-title>
      <input placeholder="Search project" (keyup)="filterProjects($event)" class="search-project">
      <div class="projects">
        <div class="table-container">
          <table #projectsSort="matSort" mat-table [dataSource]="filteredSocialTalkProjects" matSort matSortActive="project" matSortDisableClear matSortDirection="asc" (matSortChange)="sortProjects($event)">
            <!-- Project Column -->
            <ng-container matColumnDef="project">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="project">Project</th>
              <td mat-cell *matCellDef="let project">
                <mat-checkbox color="primary" [checked]="project.selected" (change)="addRemoveProject($event.checked, project.data.id)">
                  <div class="status s{{project.data.status}}"></div>
                  {{project.data.name}}
                </mat-checkbox>
              </td>
            </ng-container>

            <!-- Brand Column -->
            <ng-container matColumnDef="brand">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="brand" class="project-brand">Brand</th>
              <td mat-cell *matCellDef="let project" class="project-brand">{{(!!project.data.brand ? project.data.brand.name : '-')}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedProjectsColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedProjectsColumns;"></tr>
          </table>
        </div>
      </div>
    </mat-card>
  </div>
</div>

<div class="flex-row">
  <div class="flex-col-6">
    <mat-card>
      <mat-card-title>Prowly e-mails</mat-card-title>
      <input placeholder="Search e-mail campaign" (keyup)="filterCampaigns($event)" class="search-project">
      <div class="prowly-campaigns-container">
        <table #campaignsSort="matSort" mat-table [dataSource]="filteredProwlyCampaignsIds"
               matSort
               matSortActive="name"
               matSortDisableClear
               matSortDirection="asc"
               (matSortChange)="sortCampaigns($event)"
               class="prowly-campaigns">
          <!-- Campaign Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="name">E-mail campaign</th>
            <td mat-cell *matCellDef="let campaign" class="prowly-campaign">
              <mat-checkbox color="primary" [checked]="campaign.selected" (change)="addRemoveCampaign($event.checked, campaign.data.id)">
                <div>{{campaign.data.name}}</div>
                <div>{{campaign.data.description}}</div>
              </mat-checkbox>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedCampaignsColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedCampaignsColumns;" class="prowly-campaign"></tr>
        </table>
      </div>
    </mat-card>
  </div>
  <div class="flex-col-6">
    <mat-card>
      <mat-card-title>
        Prowly press releases
        <button *ngIf="!!report" mat-raised-button color="primary" class="small" (click)="addPressRelease()">Add</button>
      </mat-card-title>
      <div class="prowly-press-releases-container">
        <table mat-table [dataSource]="reportPressReleases" class="prowly-stories">
          <!-- Details Column -->
          <ng-container matColumnDef="details">
            <th mat-header-cell *matHeaderCellDef>Story</th>
            <td mat-cell *matCellDef="let pressRelease" class="story-details">
              <div>{{pressRelease.storyName}}</div>
              <div>{{pressRelease.journalName}}</div>
              <div>{{pressRelease.journalDomain}}</div>
            </td>
          </ng-container>

          <!-- Remove Column -->
          <ng-container matColumnDef="remove">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let pressRelease">
              <button mat-raised-button color="warn" (click)="deletePressRelease(pressRelease)" class="small" style="float:right">Remove</button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedPressReleasesColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedPressReleasesColumns;" class="prowly-story"></tr>
        </table>
      </div>
    </mat-card>
  </div>
</div>

<mat-card>
  <mat-card-title>
    Rows
    <button *ngIf="!!report && (!!hasAccess(2) || !!hasAccess(1))" mat-raised-button color="primary" class="small" (click)="deleteAllRows()">Delete all rows</button>
    <button *ngIf="!!report && (!!hasAccess(2) || !!hasAccess(1))" mat-raised-button color="primary" class="small" (click)="uploadRows()" style="margin-right:10px">Upload</button>
  </mat-card-title>
  <div class="table-container">
    <table #rowsSort="matSort" mat-table [dataSource]="data" matSort="rowsSort" matSortActive="title" matSortDisableClear matSortDirection="asc">
      <!-- Title Column -->
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
        <td mat-cell *matCellDef="let row">
          <a *ngIf="!!row.titleUrl" href="{{row.titleUrl}}" target="_blank">{{row.title}}</a>
          <span *ngIf="!row.titleUrl" class="no-link">{{row.title}}</span>
        </td>
      </ng-container>

      <!-- Theme Column -->
      <ng-container matColumnDef="theme">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Theme</th>
        <td mat-cell *matCellDef="let row">{{row.theme}}</td>
      </ng-container>

      <!-- Brand Column -->
      <ng-container matColumnDef="brand">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Brand</th>
        <td mat-cell *matCellDef="let row">{{row.brand}}</td>
      </ng-container>

      <!-- Origin Column -->
      <ng-container matColumnDef="origin">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Origin</th>
        <td mat-cell *matCellDef="let row">{{row.origin}}</td>
      </ng-container>

      <!-- Year Column -->
      <ng-container matColumnDef="year">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Year</th>
        <td mat-cell *matCellDef="let row">{{row.year}}</td>
      </ng-container>

      <!-- Month Column -->
      <ng-container matColumnDef="month">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Month</th>
        <td mat-cell *matCellDef="let row">{{row.month}}</td>
      </ng-container>

      <!-- Delete Column -->
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let row">
          <button *ngIf="!!hasAccess(2) || !!hasAccess(1)" mat-raised-button color="warn" (click)="deleteRow(row.id)" class="small" style="float:right">Delete</button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <mat-paginator [length]="resultsLength" [pageSize]="30"></mat-paginator>
</mat-card>
