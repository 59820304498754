import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

import { ReportModel } from '../core/models/report.model';
import { ReportService } from '../core/services/report.service';
import { LoaderService } from '../core/services/loader.service';
import { ReportType } from '../core/models/report-type.model';
import { AuthService } from '../core/services/auth.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements AfterViewInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  public displayedColumns: string[] = ['type', 'name', 'date', 'theme', 'pbiUrl', 'dateCreated'];
  public data: ReportModel[] = [];
  public resultsLength = 0;


  constructor(
    private authService: AuthService,
    private reportService: ReportService,
    private loaderService: LoaderService,
    private router: Router) { }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.loaderService.show();

          const loggedUser = this.authService.getLoggedUser();

          return this.reportService.get(
            (loggedUser.accessLevel === 1 ? -1 : loggedUser.id),
            false,
            0,
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.sort.active,
            this.sort.direction === 'desc');
        }),
        map(response => {
          this.loaderService.hide();
          this.resultsLength = response.totalItems;
          return response.data;
        }),
        catchError(() => {
          this.loaderService.hide();
          return observableOf([]);
        })
      ).subscribe(response => this.data = response);
  }

  getType(type: number): string {
    switch (type) {
      case ReportType.CISION: return 'CISION';
      case ReportType.CISION_IMS: return 'CISION & IMS';
      case ReportType.PROJECT: return 'PROJECT';
      default: return 'CUSTOM';
    }
  }

  createReport(): void {
    this.router.navigate([`/reports/add`]);
  }

  editReport(id: number): void {
    this.router.navigate([`/reports/${id}`]);
  }

  hasAccess(level: number): boolean {
    return this.authService.hasAccess(level);
  }
}
