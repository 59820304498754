import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AccessLevel } from '../models/access-level.model';

@Injectable()
export class AdminAccessOnlyGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {
  }


  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!!this.authService.isAuthenticated() && this.authService.user.accessLevel === AccessLevel.ADMIN) {
      return true;
    }

    return false;
  }
}
