import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

import { UserModel } from '../core/models/user.model';
import { UserService } from '../core/services/user.service';
import { LoaderService } from '../core/services/loader.service';
import { AuthService } from '../core/services/auth.service';
import { AccessLevel } from '../core/models/access-level.model';

@Component({
  selector: 'app-consultants',
  templateUrl: './consultants.component.html',
  styleUrls: ['./consultants.component.scss']
})
export class ConsultantsComponent implements AfterViewInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  public displayedColumns: string[] = ['name', 'email', 'accessLevel', 'lastLogin', 'dateCreated'];
  public data: UserModel[] = [];
  public resultsLength = 0;


  constructor(
    private authService: AuthService,
    private userService: UserService,
    private loaderService: LoaderService,
    private router: Router) { }


  ngOnInit(): void {
    if (this.authService.getLoggedUser().accessLevel === AccessLevel.CONSULTANT) {
      this.router.navigate(['/not-found']);
    }
  }

  ngAfterViewInit() {
    if (this.authService.getLoggedUser().accessLevel === AccessLevel.CONSULTANT) {
      return;
    }

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.loaderService.show();

          return this.userService.get(
            false,
            0,
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.sort.active,
            this.sort.direction === 'desc');
        }),
        map(response => {
          this.loaderService.hide();
          this.resultsLength = response.totalItems;
          return response.data;
        }),
        catchError(() => {
          this.loaderService.hide();
          return observableOf([]);
        })
      ).subscribe(response => this.data = response);
  }

  createUser(): void {
    this.router.navigate([`/consultants/add`]);
  }

  editUser(id: number): void {
    this.router.navigate([`/consultants/${id}`]);
  }
}
