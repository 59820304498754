<div class="header">
  <mat-icon (click)="back()">arrow_back_ios</mat-icon>
  <h2>{{ (!!client ? client.name : 'Create client') }}</h2>
</div>

<div class="flex-row">
  <div class="flex-col-6">
    <mat-card>
      <mat-card-title>Information</mat-card-title>
      <form [formGroup]="clientForm">
        <mat-form-field class="full-width">
          <input matInput placeholder="Name" required formControlName="name">
        </mat-form-field>
        <mat-form-field class="full-width">
          <input matInput placeholder="Code" required formControlName="code">
        </mat-form-field>
      </form>
      <button *ngIf="!!client && (!!hasAccess(2) || !!hasAccess(1))" mat-raised-button color="warn" class="small" (click)="delete()">Delete</button>
      <button *ngIf="!!hasAccess(2) || !!hasAccess(1)" mat-raised-button color="primary" class="small" (click)="submit()" [disabled]="!clientForm.valid" style="margin-left:10px">{{ (!!client ? 'Update' : 'Create') }}</button>
    </mat-card>
    <mat-card *ngIf="!!hasAccess(2) || !!hasAccess(1)">
      <mat-card-title>Permissions</mat-card-title>
      <div class="clients">
        <mat-list>
          <mat-list-item *ngFor="let consultant of consultants">
            <mat-checkbox color="primary" [checked]="consultant.selected" (change)="addRemovePermission($event.checked, consultant.data.id)" [disabled]="!hasAccess(1)">{{consultant.data.name}}</mat-checkbox>
          </mat-list-item>
        </mat-list>
      </div>
    </mat-card>
  </div>
  <div class="flex-col-6">
    <mat-card>
      <mat-card-title>
        Reports
        <button *ngIf="!!client && (!!hasAccess(2) || !!hasAccess(1))" mat-raised-button color="primary" (click)="createReport()" class="small">Create</button>
      </mat-card-title>
      <div class="table-container">
        <table mat-table [dataSource]="data" matSort matSortActive="dateCreated" matSortDisableClear matSortDirection="desc">
          <!-- Type Column -->
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
            <td mat-cell *matCellDef="let row">{{getType(row.type)}}</td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let row">
              <a (click)="editReport(row.id)">{{row.name}}</a>
            </td>
          </ng-container>

          <!-- Date Column -->
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
            <td mat-cell *matCellDef="let row">{{row.date | date}}</td>
          </ng-container>

          <!-- Theme Column -->
          <ng-container matColumnDef="theme">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Theme</th>
            <td mat-cell *matCellDef="let row">{{row.theme}}</td>
          </ng-container>

          <!-- PBI Column -->
          <ng-container matColumnDef="pbiUrl">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>PowerBI</th>
            <td mat-cell *matCellDef="let row">
              <a *ngIf="!!row.pbiUrl" href="{{row.pbiUrl}}" target="_blank">View</a>
              <span *ngIf="!row.pbiUrl">-</span>
            </td>
          </ng-container>

          <!-- Created Column -->
          <ng-container matColumnDef="dateCreated">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Created at</th>
            <td mat-cell *matCellDef="let row">{{row.dateCreated | date}}</td>
          </ng-container>

          <!-- Delete Column -->
          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
            <td mat-cell *matCellDef="let row">
              <button *ngIf="!!hasAccess(2) || !!hasAccess(1)" mat-raised-button color="warn" class="small" (click)="deleteReport(row.id)" style="float:right">Delete</button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>

      <mat-paginator [length]="resultsLength" [pageSize]="30"></mat-paginator>
    </mat-card>
  </div>
</div>
