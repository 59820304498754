import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseHttpService } from './base-http.service';
import { AuthService } from './auth.service';
import { ClientModel } from '../models/client.model';
import { ApiResponseModel } from '../models/api-response.model';
import { CreateUpdateClientModel } from '../models/create-update-client.model';
import { AddRemovePermissionModel } from '../models/add-remove-permission.model';
import { CanEditModel } from '../models/can-edit.model';

@Injectable({
  providedIn: 'root'
})
export class ClientService extends BaseHttpService {

  constructor(private authService: AuthService, private httpClient: HttpClient) {
    super();
  }


  create(data: CreateUpdateClientModel): Observable<ApiResponseModel<ClientModel>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.post<ApiResponseModel<ClientModel>>(`/api/clients/create`, data, this.httpOptions);
  }

  get(userId: number, all: boolean, page: number = 0, size: number = 10, sortField: string = 'name', sortDescending: boolean = false, searchTerm: string = ''): Observable<ApiResponseModel<Array<ClientModel>>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.get<ApiResponseModel<Array<ClientModel>>>(`/api/clients?uid=${userId}&all=${(!!all ? 1 : 0)}&p=${page}&s=${size}&sf=${sortField}&sd=${(!!sortDescending ? 1 : 0)}&t=${searchTerm}`, this.httpOptions);
  }

  getClient(id: number): Observable<ApiResponseModel<ClientModel>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.get<ApiResponseModel<ClientModel>>(`/api/clients/${id}`, this.httpOptions);
  }

  update(data: CreateUpdateClientModel): Observable<ApiResponseModel<string>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.post<ApiResponseModel<string>>(`/api/clients/update`, data, this.httpOptions);
  }

  delete(id: number): Observable<ApiResponseModel<boolean>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.delete<ApiResponseModel<boolean>>(`/api/clients/${id}`, this.httpOptions);
  }

  addPermission(data: AddRemovePermissionModel): Observable<ApiResponseModel<string>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.post<ApiResponseModel<string>>(`/api/clients/permissions/add`, data, this.httpOptions);
  }

  removePermission(data: AddRemovePermissionModel): Observable<ApiResponseModel<string>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.post<ApiResponseModel<string>>(`/api/clients/permissions/remove`, data, this.httpOptions);
  }

  canEdit(data: CanEditModel): Observable<ApiResponseModel<boolean>> {
    this.setAuthorizationToken(this.authService.getLoggedUser().token);
    return this.httpClient.post<ApiResponseModel<boolean>>(`/api/clients/can-edit`, data, this.httpOptions);
  }
}
