<mat-card>
  <button *ngIf="!!hasAccess(2) || !!hasAccess(1)" mat-raised-button color="primary" (click)="createReport()">Create</button>

  <div class="table-container">
    <table mat-table [dataSource]="data" matSort matSortActive="dateCreated" matSortDisableClear matSortDirection="desc">
      <!-- Type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td mat-cell *matCellDef="let row">{{getType(row.type)}}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let row">
          <a (click)="editReport(row.id)">{{row.name}}</a>
        </td>
      </ng-container>

      <!-- Date Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
        <td mat-cell *matCellDef="let row">{{row.date | date}}</td>
      </ng-container>

      <!-- Theme Column -->
      <ng-container matColumnDef="theme">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Theme</th>
        <td mat-cell *matCellDef="let row">{{row.theme}}</td>
      </ng-container>

      <!-- PBI Column -->
      <ng-container matColumnDef="pbiUrl">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>PowerBI</th>
        <td mat-cell *matCellDef="let row">
          <a *ngIf="!!row.pbiUrl" href="{{row.pbiUrl}}" target="_blank">View</a>
          <span *ngIf="!row.pbiUrl">-</span>
        </td>
      </ng-container>

      <!-- Created Column -->
      <ng-container matColumnDef="dateCreated">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created at</th>
        <td mat-cell *matCellDef="let row">{{row.dateCreated | date}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <mat-paginator [length]="resultsLength" [pageSize]="30"></mat-paginator>
</mat-card>
